<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    class="listing--form-modal action--modal"
    @opened="opened"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <section-loading :show="loading" />
      <!--      <section class="top&#45;&#45;part">-->
      <!--        <div class="action&#45;&#45;image">-->
      <!--          <img-->
      <!--            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/static/repost.png"-->
      <!--          />-->
      <!--        </div>-->
      <!--        <div class="action&#45;&#45;desc">-->
      <!--          <div class="desc&#45;&#45;title">-->
      <!--            {{ $t('listing.modal.repost.message1') }}-->
      <!--          </div>-->
      <!--          <div class="single&#45;&#45;desc">-->
      <!--            <div class="desc&#45;&#45;icon">-->
      <!--              <img src="@/assets/img/icons/calendar.png" />-->
      <!--            </div>-->
      <!--            <div class="desc&#45;&#45;text">-->
      <!--              {{ $t('listing.modal.repost.message2') }}-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="single&#45;&#45;desc">-->
      <!--            <div class="desc&#45;&#45;icon">-->
      <!--              <img src="@/assets/img/icons/magnifying-glass.png" />-->
      <!--            </div>-->
      <!--            <div class="desc&#45;&#45;text">-->
      <!--              {{ $t('listing.modal.repost.message3') }}-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </section>-->
      <button type="button" class="close" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
      <section class="modal--container">
        <div class="subtitle--text">
          {{ $t('listing.modal.bulkRepost.title') }}
        </div>
        <div class="action--info">
          <div class="info--title">{{ $t('listing.modal.bulkRepost.info') }}</div>
          <div class="row">
            <div class="col-6 info--coin pb-0">
              {{ $t('listing.modal.bulkRepost.listingAmount') }}
            </div>
            <div class="col-6 info--my-coin pb-0">
              <div class="coin--use">
                {{ `${amount} ${$t('listing.modal.bulkRepost.listing')}` }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 info--coin">{{ $t('listing.modal.bulkRepost.usedCoin') }}</div>
            <div class="col-6 info--my-coin">
              <div class="coin--use">
                {{ `${amount} ${$t('general.coin')}` }}
              </div>
              <div class="coin--remaining">
                {{ `${$t('listing.modal.bulkRepost.yourCoin')} ${totalCoins}` }}
              </div>
            </div>
          </div>
        </div>
        <div class="bottom--part" style="margin-bottom: 12px">
          <a class="btn btn-primary" @click="repostListing">
            {{ $t('listing.modal.bulkRepost.btn') }}
          </a>
        </div>
      </section>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
const SectionLoading = () => import('@/components/content-loading/section-loading');

export default {
  name: 'bulk-repost-listing-modal',
  components: {
    SectionLoading,
  },
  computed: {
    ...mapState({
      loading: (state) => state.coins.loading,
      totalCoins: (state) => state.coins.totalCoins,
      selectedListings: (state) => state.listing.selectedListings,
    }),
    amount() {
      return this.selectedListings.length;
    },
  },
  data() {
    return {
      modalName: 'bulk-repost-listing-modal',
      submitAction: null,
    };
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    async beforeOpen(event) {
      if (event.params && event.params.submitAction !== null) {
        this.submitAction = event.params.submitAction;
      }
    },
    async opened() {
      await this.$store.dispatch('coins/getTotalCoins');
    },
    repostListing() {
      this.$emit('submit');
      if (this.submitAction !== null) {
        this.submitAction();
      }
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.subtitle--text {
  margin-top: 20px;
  @media #{$phones} {
    margin-top: 60px;
    text-align: center;
  }
}
</style>
